import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

// markup
const NotFoundPage = () => {
  return (
    <>
      <article id='notFound'>
        {/* <title>Not found</title> */}
        <h1>404</h1>
        <div>
          <div>
            <StaticImage
              src='../images/cicovi_logo.svg'
              widht={100}
              height={100}
              alt='Logo CICOVI'
            />
          </div>
          <a href='./' className='cta'>
            Retourner à la page d'accueil
          </a>
        </div>
      </article>
    </>
  );
};

export default NotFoundPage;
